import { Form as IForm } from "@ludens-reklame/rubics-v3-sdk/form/types";
import { Image } from "@ludens-reklame/rubics-v3-sdk/file/types";
import { transformImageUrl } from "@ludens-reklame/rubics-v3-sdk/file/utils";
import { RichText } from "../../internal-components/richText/RichText.js";
import { Form } from "../../internal-components/form/form.js";

interface Props {
  strapline?: string;
  text?: string;
  image?: Image;
  form?: IForm;
  useFormDescription?: boolean;
}

const CtaForm: React.FC<Props> = ({
  strapline,
  text,
  image,
  form,
  useFormDescription = false,
}) => {
  const _text = useFormDescription
    ? form?.description
    : text
    ? text
    : undefined;

  return (
    <div className="cta-form hs vs-xl mw">
      <div className="container">
        <div className="section section-content">
          {strapline && <p className="strapline b3">{strapline}</p>}
          {_text && <RichText html={_text} />}
          {form && (
            <div className="form">
              <Form form={form} />
            </div>
          )}
        </div>

        {image && (
          <div className="section section-image">
            <img
              src={transformImageUrl(image, { height: 1080, fit: "cover" })}
              alt={image.altText}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CtaForm;
